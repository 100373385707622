.test {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pointer {
    cursor: pointer;
}

.imagen {
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    //   width: 40%;
}

.boton {
    padding: 5px;
    cursor: pointer;
}

.texto-creditos {
    font-size: 10px;
    text-align: right;
}
a {
    // text-decoration: none;
    color: white;
    font-weight: 600;
    font-size: 16px;
}

p > a {
    color: gray;
    font-weight: 500;
    font-size: 10px;
}

.texto-vinculo-tienda {
    text-align: center;
}

ion-content{
    --ion-background-color:#111D12;
}

//Bahama Swallow
// Imagen mapas
.fondo-ppal-bahama-swallow {
    background: url(../../public/assets/images/bahama-swallow/mapa-solo.png) 0 0/100% 100% no-repeat;
}

// Barras
.barra720-bahama-swallow {
    background: url(../../public/assets/images/bahama-swallow/barra720.png) 0 0/100% 100% no-repeat;
}

.barra1440-bahama-swallow {
    background: url(../../public/assets/images/bahama-swallow/barra1440.png) 0 0/100% 100% no-repeat;
}

//Background
.background-bahama-swallow {
    background-color: #D4D5B3 !important;
}

//Beach Kingfisher
// Imagen mapas
.fondo-ppal-beach-kingfisher {
    background: url(../../public/assets/images/beach-kingfisher/mapa-solo.png) 0 0/100% 100% no-repeat;
}

// Barras
.barra720-beach-kingfisher {
    background: url(../../public/assets/images/beach-kingfisher/barra720.png) 0 0/100% 100% no-repeat;
}

.barra1440-beach-kingfisher {
    background: url(../../public/assets/images/beach-kingfisher/barra1440.png) 0 0/100% 100% no-repeat;
}

//Background
.background-beach-kingfisher {
    background-color: #6E91A1 !important;
    height: 100%;
}

//Blue Jay
// Imagen mapas
.fondo-ppal-blue-jay {
    background: url(../../public/assets/images/blue-jay/mapa-solo.png) 0 0/100% 100% no-repeat;
}

// Barras
.barra720-blue-jay {
    background: url(../../public/assets/images/blue-jay/barra720.png) 0 0/100% 100% no-repeat;
}

.barra1440-blue-jay {
    background: url(../../public/assets/images/blue-jay/barra1440.png) 0 0/100% 100% no-repeat;
}

//Background
.background-blue-jay {
    background-color: #121F3A !important;
    height: 100%;
}

//green-kingfisher
// Imagen mapas
.fondo-ppal-green-kingfisher {
    background: url(../../public/assets/images/green-kingfisher/mapa-solo.png) 0 0/100% 100% no-repeat;
}

// Barras
.barra720-green-kingfisher {
    background: url(../../public/assets/images/green-kingfisher/barra720.png) 0 0/100% 100% no-repeat;
}

.barra1440-green-kingfisher {
    background: url(../../public/assets/images/green-kingfisher/barra1440.png) 0 0/100% 100% no-repeat;
}

//Background
.background-green-kingfisher {
    background-color: #3A9398 !important;
    height: 100%;
}

//magnificent-frigatebird
// Imagen mapas
.fondo-ppal-magnificent-frigatebird {
    background: url(../../public/assets/images/magnificent-frigatebird/mapa-solo.png) 0 0/100% 100% no-repeat;
}

// Barras
.barra720-magnificent-frigatebird {
    background: url(../../public/assets/images/magnificent-frigatebird/barra720.png) 0 0/100% 100% no-repeat;
}

.barra1440-magnificent-frigatebird {
    background: url(../../public/assets/images/magnificent-frigatebird/barra1440.png) 0 0/100% 100% no-repeat;
}

//Background
.background-magnificent-frigatebird {
    background-color: #FFFFFF !important;
    height: 100%;
}

//purple-euphoria
// Imagen mapas
.fondo-ppal-purple-euphoria {
    background: url(../../public/assets/images/purple-euphoria/mapa-solo.png) 0 0/100% 100% no-repeat;
}

// Barras
.barra720-purple-euphoria {
    background: url(../../public/assets/images/purple-euphoria/barra720.png) 0 0/100% 100% no-repeat;
}

.barra1440-purple-euphoria {
    background: url(../../public/assets/images/purple-euphoria/barra1440.png) 0 0/100% 100% no-repeat;
}

//Background
.background-purple-euphoria {
    background-color: #9A8CCD !important;
    height: 100%;
}

//scarlet-tanager
// Imagen mapas
.fondo-ppal-scarlet-tanager {
    background: url(../../public/assets/images/scarlet-tanager/mapa-solo.png) 0 0/100% 100% no-repeat;
}

// Barras
.barra720-scarlet-tanager {
    background: url(../../public/assets/images/scarlet-tanager/barra720.png) 0 0/100% 100% no-repeat;
}

.barra1440-scarlet-tanager {
    background: url(../../public/assets/images/scarlet-tanager/barra1440.png) 0 0/100% 100% no-repeat;
}

//Background
.background-scarlet-tanager {
    background-color: #A49173 !important;
    height: 100%;
}

//southern-martin
// Imagen mapas
.fondo-ppal-southern-martin {
    background: url(../../public/assets/images/southern-martin/mapa-solo.png) 0 0/100% 100% no-repeat;
}

// Barras
.barra720-southern-martin {
    background: url(../../public/assets/images/southern-martin/barra720.png) 0 0/100% 100% no-repeat;
}

.barra1440-southern-martin {
    background: url(../../public/assets/images/southern-martin/barra1440.png) 0 0/100% 100% no-repeat;
}

//Background
.background-southern-martin {
    background-color: #1F8FC5 !important;
    height: 100%;
}

//white-hawk
// Imagen mapas
.fondo-ppal-white-hawk {
    background: url(../../public/assets/images/white-hawk/mapa-solo.png) 0 0/100% 100% no-repeat;
}

// Barras
.barra720-white-hawk {
    background: url(../../public/assets/images/white-hawk/barra720.png) 0 0/100% 100% no-repeat;
}

.barra1440-white-hawk {
    background: url(../../public/assets/images/white-hawk/barra1440.png) 0 0/100% 100% no-repeat;
}

//Background
.background-white-hawk {
    background-color: #BBCBD3 !important;
    height: 100%;
}

//bald-eagle
// Imagen mapas
.fondo-ppal-bald-eagle {
    background: url(../../public/assets/images/bald-eagle/mapa-solo.png) 0 0/100% 100% no-repeat;
}

// // Barras
.barra720-bald-eagle {
    background: url(../../public/assets/images/bald-eagle/barra720.png) 0 0/100% 100% no-repeat;
}

.barra1440-bald-eagle {
    background: url(../../public/assets/images/bald-eagle/barra1440.png) 0 0/100% 100% no-repeat;
}

//Background
.background-bald-eagle {
    background-color: #213244 !important;
    height: 100%;
}

//brown-pelican
// Imagen mapas
.fondo-ppal-brown-pelican {
    background: url(../../public/assets/images/brown-pelican/mapa-solo.png) 0 0/100% 100% no-repeat;
}

// // Barras
.barra720-brown-pelican {
    background: url(../../public/assets/images/brown-pelican/barra720.png) 0 0/100% 100% no-repeat;
}

.barra1440-brown-pelican {
    background: url(../../public/assets/images/brown-pelican/barra1440.png) 0 0/100% 100% no-repeat;
}

//Background
.background-brown-pelican {
    background-color: #A49173 !important;
    height: 100%;
}

